/* stylelint-disable at-rule-name-case */
/* stylelint-disable selector-class-pattern */
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.App-link {
  color: #61dafb;
}
img {
  border-style: none;
  vertical-align: middle;
}
#parent-area {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
.content-warp-card {
  box-shadow: 0 1px 5px 0 rgba(51, 51, 51, 0.14);
  flex: 1;
  align-items: stretch;
}
.content-warp-card-filetreeright {
  flex: 1;
  align-items: stretch;
}
.content-warp-card-filetreeleft {
  box-shadow: 0 1px 3px 0 rgba(51, 51, 51, 0.14);
  flex: 1;
  align-items: stretch;
}
.ant-table-body {
  overflow-y: hidden !important;
}
.language-box {
  background: url("https://cdn.casbin.org/img/muti_language.svg");
  background-size: 25px, 25px;
  background-position: center;
  background-repeat: no-repeat;
  width: 45px;
  height: 65px;
  float: right;
  cursor: pointer;
}
.language-box:hover {
  background-color: #f5f5f5;
}
.select-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 45px;
  height: 64px;
  float: right;
  cursor: pointer;
}
.select-box:hover {
  background-color: #f5f5f5 !important;
}
.rightDropDown {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  float: right;
  cursor: pointer;
  margin-right: 3px;
}
.rightDropDown:hover {
  background-color: #f5f5f5;
  color: black;
}
.cs-conversation-header__content .cs-conversation-header__user-name {
  background-color: var(--theme-background) !important;
}
.cs-message--incoming .cs-message__content {
  background-color: var(--theme-background) !important;
}
.cs-message-input__content-editor-wrapper {
  background-color: var(--theme-background-secondary) !important;
}
.cs-message-input__content-editor {
  background-color: var(--theme-background-secondary) !important;
}
.cs-button--send,
.cs-button--attachment {
  color: var(--theme-button) !important;
}
.cs-message--outgoing .cs-message__content {
  background-color: var(--theme-background-secondary) !important;
}
.ps__thumb-y {
  background-color: var(--theme-background) !important;
}
.menu-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-item-icon {
  visibility: visible;
  color: inherit;
  transition: color 0.3s;
  justify-content: flex-end;
}
.suggestions-item:hover {
  background: var(--theme-color) !important;
  color: white !important;
}
